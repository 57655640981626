<template>
  <div>
    <div v-if="siteData.cryptoFeatureAvailable">
      <v-container>
        <v-overlay :value="loading" opacity="0.8">
          <div>
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-overlay>
        <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
          <v-icon left> mdi-arrow-left-thick</v-icon>
          Back
        </v-btn>
        <v-row>
          <v-col cols="12" md="8">
            <h1>Trade Cryptocurrency</h1>
            <!--            <p class="text-subtitle-1 font-weight-bold">-->
            <!--              Got questions? Call or Whatsapp {{ siteData.phone }}-->
            <!--            </p>-->
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              label="Search Currency"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="searchedCurrency.length == 0 && !loading">
            <v-card>
              <v-container>
                <v-card-title class="text-center">Card Not Found</v-card-title>
                <v-card-subtitle>
                  <b>"{{ search }}"</b> is not available at the moment
                </v-card-subtitle>
              </v-container>
            </v-card>
          </v-col>
          <v-col
            v-else
            v-for="card in searchedCurrency"
            :key="card._id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                ripple
                :to="
                  card.hasAutoProcessing
                    ? `/user/products/crypto/auto-trade/${card._id}`
                    : `/user/products/crypto/trade/${card._id}`
                "
                :elevation="hover ? '8' : '2'"
                rounded="lg"
                class="pa-5 text-center relative"
              >
                <span
                  v-if="card.hasAutoProcessing"
                  class="bg-yellow-200 absolute left-1 bottom-1 block px-2 py-1 text-black z-10 m-0 rounded-full"
                  style="border-radius: 15px; font-size: 12px"
                >
                  Auto Processing Available
                </span>
                <v-img contain :src="card.image" height="115"></v-img>
                <v-card-title class="text-center mx-auto">
                  <span
                    style="font-size: 16px"
                    class="mx-auto font-bold text-uppercase"
                    >{{ card.name }}</span
                  >
                </v-card-title>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-card flat class="pa-5" rounded="lg">
        {{ siteData.cryptoFeatureMessage }}
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../../api/systemData'

export default {
  data: () => ({
    search: '',
    currencies: [],
    loading: false
  }),
  created() {
    this.$store.dispatch('user/getSiteSettings')
    this.getAllGiftcard()
    this.$store.dispatch('user/getCountryCurrency')
  },
  computed: {
    ...mapState({
      siteData: (state) => state.user.siteSettings
    }),
    searchedCurrency() {
      if (this.search) {
        return this.currencies.filter(
          (currency) =>
            currency.name
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            currency.symbol
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase())
        )
      }
      return this.currencies
    }
  },
  methods: {
    async getAllGiftcard() {
      this.loading = true
      const res = await dataApi.data().getData('user', { type: 'currency' })
      this.currencies = res.data.data.data
        .filter((c) => c.status === true)
        .sort((a, b) => {
          const textA = a.name.toLowerCase()
          const textB = b.name.toLowerCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
      this.loading = false
    }
  }
}
</script>

<style></style>
